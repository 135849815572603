import React from "react";
// import Spinner from 'react-bootstrap/Spinner'
import './spinner.css'
export default function Loader() {
    return (
        <div className="loader"></div>
        // <Spinner animation="border" role="status">
        //     <span className="sr-only">Loading...</span>
        // </Spinner>
        
    );
}