import React from "react";
// import {Row,Col,Image,Form,Button} from 'react-bootstrap'
// import Logo from '../../assets/images/logo.png'
import NavBar from "./NavBar";
// import Col from "react-bootstrap/Col";
// import Row from "react-bootstrap/Row";
// import Container from "react-bootstrap/Container";
export default function Header({ history }) {
    return(
        <>
            <NavBar />
        </>

    )
}