import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Logo from "../../assets/images/logo.svg";
import footerlogo from "../../assets/images/footerlogo.png";
import OMedium from '../../assets/images/footer/footerNav/OrangeMedium.svg'
import OTelegram from '../../assets/images/footer/footerNav/OrangeTelegram.svg'
import OTwitter from '../../assets/images/footer/footerNav/OrangeTwitter.svg'
import OYoutube from '../../assets/images/footer/footerNav/OrangeYoutube.svg'
import ODiscord from '../../assets/images/footer/footerNav/OrangeDiscord.svg'
import OInstagram from '../../assets/images/footer/footerNav/orangeinsta.svg'
import scroll from '../../assets/images/social/scoral up.svg'
export default function Footer({ history }) {
    function scrollToTop(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    return(
        <footer className="footer">
            <Container fluid className="footerContainer">
                <Row className="footerRow d-flex justify-content-center">
                <MDBFooter bgColor='none'  className='text-center text-lg-start text-white' target="_blank">
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
       
        <div className="footersocial" >
          <a href='https://twitter.com/Metal_Roars' className='me-4 text-reset' target="_blank">
            <i ><img src={OTwitter}  /></i>
          </a>
          <a href='' className='me-4 text-reset'>
          <i ><img src={OInstagram}  /></i>
          </a>
          <a href='https://www.youtube.com/channel/UCNHnVkj0RBT32MG7W6Op_9Q' className='me-4 text-reset' target="_blank">
          <i ><img src={OYoutube}  /></i>
          </a>
          <a href='https://t.me/metalroars' className='otelegram' target="_blank">
          <i ><img src={OTelegram}  /></i> 
          </a>
        </div>
      </section>

      <section className=''>
        <div className='container text-center text-md-start mt-5'>
          <div className='row mt-3'>
            <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
              <h1 className='text-uppercase fw-bold mb-4'>
             < Image className="footerLogo" src={footerlogo} alt="metalroar logo" />
             
              
              </h1>
              <p>
              </p>
            </div>

            <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='majorlink'>MAJOR LINK</h6>
              <p>
                <a href='https://metal-roars.gitbook.io/metal-roars-whitepaper-v.1-1/' className='text-reset'target="_blank">
                  WHITEPAPER
                </a>
              </p>
              <p>
                <a href='https://drive.google.com/file/d/1zAJJ5V9jcmE1bO8azyP8Dqv3mqKsPn83/view?usp=sharing' className='text-reset' target="_blank">
                  INVESTMENTS DECK
                </a>
              </p>
              <p>
                <a href='https://metalroars.com/' className='text-reset' target="_blank">
                  NFT GRAPHICS
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset' target="_blank">
                  MARKETPLACE
                </a>
              </p>
            </div>

            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='majorlink'>MENU</h6>
              <p>
                <a href='https://metalroars.com/' className='text-reset'>
                  HOME
                </a>
              </p>
              <p>
                <a href='https://metalroars.com/#marketplace' className='text-reset' target="_blank">
                  MARKETPLACE
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'target="_blank">
                  STAKING
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'target="_blank">
                  VESTING
                </a>
              </p>
            </div>

            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
              <h6 className='majorlink'>CONTACT US</h6>
              <p>
                <i className='fas fa-home me-3'></i> Address: M9B 4L5 CA
              </p>
              <p>
                <i className='fas fa-envelope me-3'></i>
                contact@metalroars.com
              </p>
              
             
            </div>
          </div>
        </div>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.10)' }}>
        © 2022 Copyright:
        <a className='text-reset fw-bold' href='https://metalroars.com/'>
           metalroars
        </a>
      </div>
    </MDBFooter>
                </Row>

            </Container>
        </footer>
    )
}
